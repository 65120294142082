<template>
    <v-spa-layout-page>
        <template v-slot:title>{{ $t('visibility.ratings.index.title') }}</template>

        <div slot="title-button">
            <v-spa-loading-button @clicked="$modal.show('rating-collect-modal');">
                {{ $t('visibility.ratings.index.button') }}
            </v-spa-loading-button>
        </div>

        <template v-slot:content>
            <div class="relative p-16" v-if="loading">
                <v-spa-loader></v-spa-loader>
            </div>
            <template v-else>
                <div class="mx-auto py-12" v-if="!rows.length">
                    <div class="w-[400px] mx-auto">
                        <v-spa-lottie-empty-2></v-spa-lottie-empty-2>
                    </div>
                    <p class="my-12 text-lg font-thin text-center text-gray-700">{{ $t('visibility.ratings.index.empty') }}</p>
                </div>
                <table class="w-full" v-else>
                    <thead>
                    <tr>
                        <th :class="$spa.css.table.th_first">
                            {{ $t('visibility.ratings.table.headings.user') }}
                        </th>
                        <th :class="$spa.css.table.th">
                            {{ $t('visibility.ratings.table.headings.rating') }}
                        </th>
                        <th :class="$spa.css.table.th">
                            {{ $t('visibility.ratings.table.headings.text') }}
                        </th>
                        <th :class="$spa.css.table.th_last">
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        <rating-row :rating="rating" v-for="rating in rows" :key="rating.id" @clicked="openRating(rating)"></rating-row>
                    </tbody>
                </table>
                <div class="flex justify-center mt-6" v-if="pagination && pagination.last_page !== 1">
                    <paginate
                        v-model="pagination.current_page"
                        :no-li-surround="true"
                        :page-count="pagination.last_page"
                        :click-handler="getRatings"
                        :prev-text="$t('pagination.previous')"
                        :prev-link-class="'prev'"
                        :next-text="$t('pagination.next')"
                        :next-link-class="'next'"
                        :container-class="'pagination'"
                        :active-class="'active'"
                        ref="paginate"
                    ></paginate>
                </div>

                <portal to="modals">
                    <v-spa-right-panel-modal name="rating-details-modal" right-panel-width="650px" right-panel-width-xl="650px">
                        <div class="flex flex-col h-screen" v-if="rating">
                            <div class="flex-1">
                                <h2 class="mb-2 text-xl font-medium text-slate-800">
                                    {{ rating.user.name }}
                                </h2>
                                <div class="mb-8">
                                    <stars :value="rating.value"></stars>
                                </div>
                                <p class="text-sm text-slate-600" v-html="rating.text"></p>
                                <p class="mt-2 text-xs font-thin text-slate-400">Source: TrustUp.be</p>
                                <div class="pt-6 mt-6 border-t border-gray-300">
                                    <template v-if="rating.answer">
                                        <span class="block mb-2 text-left text-gray-700">{{ $t('visibility.ratings.card.answer_title') }}</span>
                                        <p class="text-sm font-thin text-gray-500" v-if="rating.answer">
                                            {{ rating.answer }}
                                        </p>
                                    </template>
                                    <form @submit.prevent="answer" v-if="! rating.answer">
                                        <v-spa-textarea id="form_answer" :form="form" containerClassName="mb-2" :class-name="$spa.css.form.input_full_width" input-name="answer" :display-label="false" :placeholder="$t('visibility.ratings.card.textarea')"></v-spa-textarea>
                                        <div class="flex justify-end">
                                            <v-spa-loading-button :loading="form.is_loading">{{ $t('visibility.ratings.card.buttons.answer') }}</v-spa-loading-button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div>
                                <v-spa-loading-button ref="reportButton" @clicked="report" class-name="relative rounded px-4 py-2 text-red-700 hover:text-red-800 transition-all transition-250" v-if="! rating.is_reported">{{ $t('visibility.ratings.card.buttons.report') }}</v-spa-loading-button>
                            </div>
                        </div>
                    </v-spa-right-panel-modal>
                </portal>

                <portal to="modals-sub">
                    <v-spa-right-panel-modal name="rating-collect-modal" right-panel-width="550px" right-panel-width-xl="550px">
                        <collect></collect>
                    </v-spa-right-panel-modal>
                </portal>
            </template>
        </template>

    </v-spa-layout-page>
</template>

<script>
    import Form from '~spa/classes/Form';
    import mixin from '~spa/components/_Table/index-mixin.js';
    import CardVue from './Card.vue';
    import Row from './_Row.vue';
    import StarsVue from '~base/components/_Partials/Stars.vue';
    import Paginate from 'vuejs-paginate';
    import Page from '~base/components/_Layout/TmpSpa/Layout/Page';
    import Collect from "./Collect";

    export default {
        components: {
            Paginate,
            'stars': StarsVue,
            'rating-row': Row,
            'rating-card': CardVue,
            'tmp-spa-page': Page,
            Collect
        },

        mixins: [mixin],

        data() {
            return {
                pagination: null,
                rating: null,
                form: new Form({
                    answer: ''
                })
            }
        },

        methods: {
            openRating(rating) {
                console.log('open rating');
                this.rating = rating;
                this.form.answer = '';
                this.$modal.show('rating-details-modal');
            },

            answer()
            {
                this.form.post(`${window.api_url}/me/professionals/${this.$store.state.professional.id}/ratings/${this.rating.id}/answer`)
                    .then((data) => {
                        window.Toasteo.success(
                            this.$t('visibility.ratings.card.answered')
                        );
                        this.rows = this.rows.map(rating => rating.id !== data.rating.id ? rating : data.rating);
                        this.$modal.hide('rating-details-modal');
                });
            },

            report()
            {
                if ( this.$refs.reportButton.submitted ) {
                    return;
                }

                this.$refs.reportButton.load();
                axios.post(`${window.api_url}/me/professionals/${this.$store.state.professional.id}/ratings/${this.rating.id}/report`)
                    .then((response) => {
                        window.Toasteo.success(
                            this.$t('visibility.ratings.card.reported')
                        );
                        this.rows = this.rows.map(rating => rating.id !== response.data.rating.id ? rating : response.data.rating);
                        this.$modal.hide('rating-details-modal');
                        this.$refs.reportButton.stop();
                    });
            },

            getRatings(page = 1)
            {
                this.loading = true;

                this.$store.dispatch('getRatings', {professionalId: this.$store.state.professional.id, page: page})
                    .then((data) => {
                        this.rows = data.ratings;
                        this.pagination = data.pagination;
                        this.loading = false;
                        if ( this.$route.name == 'professional.visibility.ratings.index' ) {
                            this.$router.replace({name: 'professional.visibility.ratings.index', params: {professional: this.$store.state.professional.id}, query: {page: this.pagination.current_page}}).catch(()=>{});
                        }
                    });
            }
        },

        created() {
            this.$store.commit('SIDEBAR/SET_SECTION', 'visibility-ratings');
            this.getRatings(this.$route.query.page);
        }
    }
</script>
